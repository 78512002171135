<template>
  <div>
    <div class="header">
      <p>易语通</p>
    </div>
    <div class="login">
      <div class="l1">
        <img src="../static/首页/zhanghao.png" alt="" width="15" height="20">
        <van-field v-model="name" id='name'  placeholder="请输入用户名" />
      </div>
      <div class="l2">
        <img src="../static/首页/mima.png" alt="" width="15" height="20">
         <van-field v-model="password" id='password'  placeholder="请输入密码"  type="password"/>
      </div>
      <van-button round type="info" class="btn" @click="login">确认登陆</van-button>
    </div>
  </div>
</template>

<script>
	import { Message } from 'iview'
import global from '../App.vue'
	
import md5 from 'js-md5';
export default {
  data() {
	  
	  	console.log(global.token);
	  
    return {
      name:'',
      password:'',
    }
  },methods: {
    login(){
		let param = new URLSearchParams();
		
		 param.append("name", this.name);
		 param.append("password", md5(this.password));
		
		this.axios.post('/vue.php?m=user&a=login',param).then(res => {
			if (res.data.status === 1) {
				// uni.setStorageSync('name',res.data.name);
				// uni.setStorageSync('session_id',res.data.session_id);
				// uni.setStorageSync('token',res.data.token);
				global.name = res.data.name;
				global.session_id = res.data.session_id;
				global.token = res.data.token;
				this.$store.commit('setDemoValue', res.data.token);    


				Message.info(res.data.info);
				setTimeout(()=>{
					this.$router.push({
					  path:"/crm"
					})
				},1000)
			}
			if (res.data.status === 0) {
			
			
				Message.info(res.data.info);
			}
			console.log(res);
		}).catch(function (error){
			Message.info('hello');
			console.log(error);
		});
		
      // this.$router.push({
      //   path:"/crm"
      // })
    }
  },
};
</script>

<style lang="scss"scoped>
.header {
  background: url("../static/首页/beijingtu.png") no-repeat;
  background-size: cover;
  height: 435px;
  display: flex;
  justify-content: center;
  align-items: center;
  p {
    width: 108px;
    height: 33px;
    font-size: 34px;
    font-family: Microsoft YaHei;
    font-weight: 400;
    color: #ffffff;
  }
}
.btn{
  width: 217px;
height: 48px;
display:block;
margin:20px auto;
}
.login {
  .txt {
    height: 16px;
    font-size: 17px;
    font-weight: normal;
    color: #545454;
    margin-left: 40px;
    opacity: 0.5;
  }
  .l1 {
    width: 280px;
    height: 61px;
    margin: 0 auto;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    align-items: center;
  }
  .l2 {
    width: 280px;
    height: 61px;
    margin: 0 auto;
    align-items: center;
    display: flex;
    border-bottom: 1px solid #f1f1f1;
  }
}
</style>